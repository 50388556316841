import React, { FC, memo, useMemo } from 'react';
import NextLink from 'next/link';
import Typography from '@material-ui/core/Typography';

import { Link } from '../../base.types';
import { LinksProps } from './links.types';
import { List, ListItem, Group } from './links.styles';

const COLUMNS = 3;
const ROWS = 3;

type RenderLink = (link: Link) => JSX.Element;

const renderLink: RenderLink = ({ name, href }) => (
  <ListItem disableGutters key={name}>
    <NextLink href={href} prefetch={false} passHref>
      <a>
        <Typography variant="body2" color="inherit">
          {name}
        </Typography>
      </a>
    </NextLink>
  </ListItem>
);

export const Links: FC<LinksProps> = memo(({ data }) => {
  const emptyGroups: undefined[] = useMemo(
    () => [...Array(Math.floor(data.length / COLUMNS))],
    [data]
  );
  const withGroup = useMemo(() => emptyGroups.length >= 2, [emptyGroups]);

  if (withGroup) {
    const groups = emptyGroups.map((_, index) =>
      data.slice(index * ROWS, index * ROWS + ROWS)
    );

    return (
      <List disablePadding>
        {groups.map((group, key) => (
          <Group key={key}>{group.map(renderLink)}</Group>
        ))}
      </List>
    );
  }

  return <List disablePadding>{data.map(renderLink)}</List>;
});
