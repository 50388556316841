import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MUIList from '@material-ui/core/List';
import MUIListItem, { ListItemTypeMap } from '@material-ui/core/ListItem';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Box from '@material-ui/core/Box';

export const Social = styled(Grid)`
  ${({ theme: { breakpoints } }) => `
    height: 100%;

    ${breakpoints.up('lg')} {
      padding: 6px 0;
    }
  `}
`;

export const Text = styled(Typography)`
  ${({ theme: { palette } }) => `
    color: ${palette.common.white};
    opacity: 0.4;
  `}
`;

export const List = styled(MUIList)`
  width: 225px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const ListItem: OverridableComponent<
  ListItemTypeMap<{ button?: false }, 'li'>
> &
  ExtendButtonBase<ListItemTypeMap<{ button: true }, 'div'>> = styled(
  MUIListItem
)`
  width: auto;
  padding: 0;
`;

export const Texts = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
