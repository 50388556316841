import React, { FC, memo } from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';

import {
  Social as UISocial,
  Text,
  List,
  ListItem,
  Texts,
} from './social.styles';

const SOCIALS: Social[] = [
  {
    href: 'https://www.facebook.com/KeazeUK/',
    icon: '/svg/facebook.svg',
  },
  {
    href: 'https://www.instagram.com/keazeuk',
    icon: '/svg/instagram.svg',
  },
  {
    href: 'https://www.linkedin.com/company/keazeuk/',
    icon: '/svg/linked-in.svg',
  },
  {
    href: 'https://twitter.com/keazeuk',
    icon: '/svg/twitter.svg',
  },
];

type Props = {
  className?: string;
};

type Social = {
  href: string;
  icon: string;
};

type RenderSocial = (social: Social) => JSX.Element;

const renderSocial: RenderSocial = ({ href, icon }) => (
  <ListItem key={href} disableGutters>
    <NextLink href={href} prefetch={false} passHref>
      <a>
        <NextImage
          src={icon}
          width={22}
          height={22}
          objectFit="contain"
          objectPosition="center center"
        />
      </a>
    </NextLink>
  </ListItem>
);

const getYear = () => new Date().getFullYear();

export const Social: FC<Props> = memo(({ className }) => {
  const cookieSettingsLink = `<a class="change-consent" onclick="window.__tcfapi('displayConsentUi', 2, function() {} );">Cookie settings</a>`;
  const currentYear = getYear();

  return (
    <UISocial
      container
      direction="column"
      justify="flex-end"
      className={className}
    >
      <List disablePadding>{SOCIALS.map(renderSocial)}</List>
      <Texts>
        <Text variant="body2">
          &copy; {currentYear} Keaze. All rights reserved
        </Text>
        <Text
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: cookieSettingsLink,
          }}
        />
      </Texts>
    </UISocial>
  );
});
