import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import MUIList from '@material-ui/core/List';
import MUIListItem, { ListItemTypeMap } from '@material-ui/core/ListItem';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const Group = styled(Box)``;

export const ListItem: OverridableComponent<
  ListItemTypeMap<{ button?: false }, 'li'>
> = styled(MUIListItem)`
  ${({
    theme: {
      transitions: { duration, easing },
      palette,
      colors,
    },
  }) => `
    font-weight: 600;
    padding: 6px 0;
    align-items: flex-start;

    & > a {
      color: ${palette.common.white};
      transition: ${duration.shorter}ms ${easing.easeInOut};

      &:hover,
      &:active,
      &:focus {
        color: ${colors.blues.blues2};
      }
    }
  `}
`;

export const List = styled(MUIList)`
  ${({ theme: { breakpoints } }) => `
    display: flex;
    margin-top: 24px;
    flex-wrap: wrap;

    & > ${Group} {
      flex: 1 1 100%;
    }

    ${breakpoints.up('md')} {
      & > ${Group} {
        flex: 1 0 50%;
      }
    }

    ${breakpoints.up('lg')} {
      flex-wrap: nowrap;

      & > ${Group} {
        flex: 1 1 100%;
      }
    }
  `}
`;
