import styled from 'styled-components';
import MUITypography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export const Footer = styled(MUIGrid)`
  ${({ theme: { palette, breakpoints } }) => `
    background-color: ${palette.primary.main};
    padding: 40px 0 140px 0;
    overflow: hidden;
    flex: 0 0 auto;

    ${breakpoints.up('md')} {
      padding: 50px 0;
    }
  `}
`;

export const Partnerships = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    display: flex;
    flex-direction: column;

    ${breakpoints.up('lg')} {
      align-items: center;

      ul {
        flex-direction: column;
      }

      li {
        width: auto;
      }
    }
  `}
`;

export const PartnershipsOur = styled(MUITypography)`
  ${({
    theme: {
      palette,
      typography: { pxToRem },
    },
  }) => `
    color: ${palette.secondary.main};
    font-weight: 700;
    line-height: ${pxToRem(20)};
    margin-bottom: -4px;
  `}
`;

export const PartnershipsTitle = styled(MUITypography)`
  ${({ theme: { palette, breakpoints } }) => `
    color: ${palette.common.white};
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;

    ${breakpoints.up('mb')} {
      font-size: 23px;
    }
  `}
`;

export const Grid = styled(MUIGrid)`
  ${({ theme: { palette, breakpoints } }) => `
    & > .MuiGrid-item {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 32px;
        width: calc(100% - 64px);
        height: 1px;
        background-color: ${palette.common.white};
        opacity: 0.1;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      ${breakpoints.up('lg')} {
        &::after {
          top: 50%;
          right: 0;
          left: auto;
          bottom: auto;
          width: 1px;
          height: 82%;
          transform: translateY(-50%);
        }

        &:nth-child(2) {
          &::after {
            right: 0;
          }
        }
      }
    }
  `}
`;

export const LogoWrapper = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    position: relative;
    width: 100px;
    height: 28px;

    ${breakpoints.up('md')} {
      width: 136px;
      height: 38px;
    }
  `}
`;
