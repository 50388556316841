import { FC, memo } from 'react';
import NextImage from 'next/image';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Link } from '../base.types';
import {
  Footer as UIFooter,
  Partnerships,
  PartnershipsOur,
  PartnershipsTitle,
  Grid,
  LogoWrapper,
} from './footer.styles';
import { Links } from './links';
import { Social } from '../social';

const LINKS: Link[] = [
  {
    name: 'Privacy policy',
    href: '/privacy',
  },
  {
    name: 'Cookie policy',
    href: '/cookie-policy',
  },
  {
    name: 'Terms of use',
    href: '/terms-of-use',
  },
  {
    name: 'Shared Ownership FAQ',
    href: '/faq/shared-ownership',
  },
  {
    name: 'Help to Buy FAQ',
    href: '/faq/help-to-buy',
  },
  {
    name: 'Other Schemes FAQ',
    href: '/faq/other-available-schemes',
  },
  {
    name: 'About us',
    href: '/about',
  },
  //  {
  //  name: 'Blog',
  //    href: '/blog',
  //  },
  {
    name: 'Keaze for Business',
    href: 'https://partners.keaze.com',
  },
];

const PARTNERSHIPS_LINKS: Link[] = [
  {
    name: 'KPro Software',
    href: 'https://kprosoftware.co.uk/',
  },
  {
    name: 'Homes for Londoners',
    href:
      'https://www.london.gov.uk/what-we-do/housing-and-land/homes-londoners/search/',
  },
  {
    name: 'SharedOwnership.net',
    href: 'https://sharedownership.net/',
  },
  {
    name: 'National Housing Federation',
    href: 'https://www.housing.org.uk/',
  },
];

const renderMain = () => (
  <Box>
    <LogoWrapper>
      <NextImage src="/svg/logo-white.svg" layout="fill" quality={90} />
    </LogoWrapper>
    <Links data={LINKS} />
  </Box>
);

const renderPartnerships = () => (
  <Partnerships>
    <Box>
      <Box component="header">
        <PartnershipsOur variant="body1">Our</PartnershipsOur>
        <PartnershipsTitle variant="body1">Partnerships</PartnershipsTitle>
      </Box>
      <Links data={PARTNERSHIPS_LINKS} />
    </Box>
  </Partnerships>
);

export const Footer: FC = memo(() => (
  <UIFooter>
    <Container>
      <Grid container justify="space-between" spacing={8}>
        <Grid item xs={12} md={6} lg={6}>
          {renderMain()}
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          {renderPartnerships()}
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Social />
        </Grid>
      </Grid>
    </Container>
  </UIFooter>
));
